const services = [   
    {
        id: '1',
        photos: [
            // 'https://storage.googleapis.com/test-goguau.appspot.com/resources/paseo2-min.jpeg',
            'https://storage.googleapis.com/test-goguau.appspot.com/resources/molly.jpeg',
            'https://storage.googleapis.com/test-goguau.appspot.com/resources/dasha-min1.jpeg', 
            'https://storage.googleapis.com/test-goguau.appspot.com/resources/zeusycaicer-min1.jpeg'
        ],        
        name: 'Paseos para mascotas',
        durationHours: null,
        durationMinutes: 45,
        prices: [
            {
                cityId: 4,//cali
                price: 9900
            },
            {
                cityId: 37,//pasto
                price: 9900
            },
            {
                cityId: 1,//popayán
                price: 9900
            },
        ],
        currencyCop: 8800,
        currencyPen: 10,
        type:1,
        giveaway: false,
        youtubeUrl: 'https://www.youtube.com/embed/kHvhUI_IhOI?rel=0',
        city: 'Popayán',        
        participants: 4,  
        about: [
            "Ofrecemos el servicio de paseo confiable para tu mejor amigo.",
            "Recibirás un servicio profesional y confiable, recogemos a tu mascota en la puerta de tu hogar.",    
            "Incluye seguimiento GPS y conteo de distancia en mapa.",
            "Usamos bolsas biodegradables.",
            "Contamos con paseadores cuyos perfiles han sido verificados y brindarán un cuidado profesional a tu mascota.",
            "Llevamos un máximo de 4 mascotas y podrás agendar el paseo diferentes día de la semana, el horario dependen del barrio de ubicación."
        ],
        requirements: [
            'Mascotas con correa y collar',
            'Bozal si la mascota es de raza potencialmente peligrosa o bravo',
            'Vacunas al día, como mínimos las 3 primeras vacunas.'            
        ],       
        terms: 'www.goguau.co/pages/terms'
    },
    {
        id: '2',        
        photos: [            
            'https://storage.googleapis.com/test-goguau.appspot.com/resources/cocoa-min.jpg',
            'https://storage.googleapis.com/test-goguau.appspot.com/resources/Leo.jpeg',
            'https://storage.googleapis.com/test-goguau.appspot.com/resources/zeus-min1.jpeg'
            
        ],
        name: 'Adiestramiento',
        durationHours: 1,
        durationMinutes: null,
        prices: [
            {
                cityId: 4,//cali
                price: 20500
            },
            {
                cityId: 37,//pasto
                price: 20500
            },
            {
                cityId: 1,//popayán
                price: 20500
            },
        ],
        currencyCop: 19800,
        currencyPen: 10,
        type:1,
        giveaway: false,
        youtubeUrl: 'https://www.youtube.com/embed/kHvhUI_IhOI?rel=0',
        city: 'Popayán',
        participants: 1,  
        about: [
            "Ofrecemos el servicio de adiestramiento canino para tu mejor amigo.",
            
            "Duración 1 hora.",
            "Incluye seguimiento GPS y conteo de distancia en mapa.",
            "Contamos con paseadores cuyos perfiles han sido verificados y brindarán un servicio profesional a tu mascota.",
            "Este servicio se brinda de manera personalizada, el horario dependen del barrio de ubicación.",
            "Recibirás un servicio profesional y confiable, recogemos a tu mascota en la puerta de tu hogar para clases personalizadas.",
        ],
        requirements: [
            "El adiestramiento consiste en enseñanza de comandos de nivel básico e intermedio como: acostarse, acudir al llamado, caminar junto al amo sin halar del collar, pararse, prestar atención, quedarse quieto, saludo, sentarse.",
            'No incluye galletas-premios de entrenamiento.',
            'Son clases personalizadas',
        ],
        terms: 'www.goguau.co/pages/terms'
    },
    {
        id: '35',        
        photos: [            
            'https://storage.googleapis.com/test-goguau.appspot.com/resources/g1.png',
            'https://storage.googleapis.com/test-goguau.appspot.com/resources/g2.png'
        ],
        name: 'Guardería',
        durationHours: 24,
        durationMinutes: null,
        currencyCop: 36000,
        
        currencyPen: 40,
        type:1,
        giveaway: false,
        youtubeUrl: null,
        city: 'Popayán',
        participants: 1,  
        about: [
            "Ofrecemos el servicio de guardería para tu mejor amigo.",
            
            "Tu mascota estará alojada en el hogar de uno de nuestros anfitriones (paseadores) de perfil verificado. " 
            + "De esta manera ofrecemos unas condiciones mas saludable fisicas y mentales para tu mejor amigo.",
            "Cuidado personalizado.",
            "No usamos jaulas o guacales.",
            "Constante evidencia audiovisual",
            "Dos minisalidas para necesidades.",
            "**SERVICIO ESTANDAR**",
            "Alojamiento y cuidado",
            "**SERVICIO PREMIUM**",
            "Alojamiento, cuidado, cámara vídeo, micrófono y comedor electrónico con manejo por medio de app móvil",
        ],
        requirements: [
            "Suministro de cama, alimento y recipientes.",
            'Entrega y recogida en el hogar del anfitrión',
        ],
        terms: 'www.goguau.co/pages/terms'
    },
    {
        id: '3',
        photos: [                        
            'https://storage.googleapis.com/test-goguau.appspot.com/resources/tagqr/placas2.jpg',            
            'https://storage.googleapis.com/test-goguau.appspot.com/resources/tagqr/placas1.jpg',            
            'https://storage.googleapis.com/test-goguau.appspot.com/resources/1a1/1-min.jpg',                        
        ], 
        name:'Placas de identificación QR',
        durationHours: null,
        durationMinutes: null,
        currencyCop: 50000,
        currencyPen: 10,
        type:2,
        giveaway: false,        
        youtubeUrl: 'https://www.youtube.com/embed/k2CO005Bp0w?rel=0',        
        // youtubeUrl: null,
        city: 'Envíos a todo Colombia, pago de envío contraentrega ',
        participants: null,  
        about: [
            "Toma una sabia decisión y protege a tus mascotas (perros y gatos) con las placas Go Guau.",
            "¡No más peludos perdidos!",
            // "Cada vez que la placa sea leída, el usuario visualizará la información de tu mascota en nuestra plataforma web www.goguau.co. Además, recibirás un correo electrónico con la ubicación GPS donde se hizo la lectura, y podrán contactarte por whatsapp en menos de lo que canta un gallo.",
            "En caso de pérdida de tu peludo, quien lea el código QR de la placa podrá ver el perfil digital de tu mascota con la información de donde contactarte. Podrán llamarte o escribirte a Whatsapp en menos de lo que canta un gallo. Ten presente que también recibirás un correo con la ubicación en donde fue leída la placa de tu mascota.",
           
            "Perfil digital: registra tus datos de contacto y el perfil digital de tu mascota en nuestra plataforma web. El perfil será visible en cualquier celular android, iOS o computadora.",
            "Perfil actualizado: olvídate de comprar otra placa si tus datos de contacto cambian. Actualiza tus datos de contacto en nuestra plataforma web cuando y cuantas veces quieras.",
            "Funcionalidades:",
            "- Notificación vía correo electrónico con ubicación GPS",
            "- Link de whatsapp",
            "- Botón de llamado a tu número de contacto.",
            "Placa",
            "- Hecha en aluminio",
            "- Grabado en láser, resistente al agua y lavado",
            "Nota: primer año gratis del perfil digital. Luego, costo de administración anual 10.000 cops"
          ],
        requirements: [
            'Activa tu placa en solo 5 pasos:',
            '1. Desde tu celular regístrate en www.goguau.co',
            '2. Completa tu perfil',
            '3. Crea el perfil de tu(s) mascota(s) en: Menú/ Mi cuenta/ Mis mascotas',
            '4. Con tu cámara lee el código QR de la placa y abre el link asociado',
            '5. Asigna la placa a la mascota que deseas'
        ],
        terms: 'www.goguau.co/pages/terms'
    },
    // {
    //     id: '34',        
    //     photos: [            
    //         'https://storage.googleapis.com/test-goguau.appspot.com/resources/arnes/arnes2.png',                        
    //     ],
    //     name: 'Arnés antitirones',
    //     durationHours: null,
    //     durationMinutes: null,
    //     currencyCop: 99000,
    //     currencyPen: 90,
    //     type:2,
    //     giveaway: false,
    //     youtubeUrl: null,
    //     city: 'Envíos a todo Colombia, pago de envío contraentrega ',
    //     participants: null,  
    //     about: [
    //       "El diseño del arnés minimiza o elimina los tirones y la tensión del cuello para un mejor control sobre tu mascota", 
    //       "Producto fabricado a medida",
    //       "Garantía 30 días",          
    //       "Cómodo y duradero: fabricado en riata de alta calidad que garantizan larga duración",
    //       "Doble función: se puede utilizar en modo anti tirones si se fija en la parte delantera o para hacer ejercicio cuando se fija en la espalda",
    //       "Reduce el tirón: el bucle de acción mejorado se contrae cuando hay tirones y vuelve a posición inicial para paseos más cómodos y sin maltrato",                                            
    //     ],
    //     requirements: [
    //         "Envío de medidas de la mascota para iniciar fabricación",     
    //     ],
    //     terms: null
    // },
    
]

export default services