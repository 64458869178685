<template>
  <div>
    <b-modal
      title="Selecciona tu ciudad"
      v-model="show"
      no-close-on-backdrop
      hide-header-close
      size="md"
    >
      <b-row>
        <b-col>
           <b-col>
              <b-form-group
                id="city"
                label="Ciudad:"
                label-for="city"
                v-if="cities.length > 0"
              >
                <b-form-select
                  id="city"
                  v-model.trim="$v.citySelected.$model"
                  :options="cities"                  
                  :class="{
                    'form-control is-invalid': $v.citySelected.$error,
                    'form-control is-valid': !$v.citySelected.$invalid
                  }"
                ></b-form-select>
                <b-form-invalid-feedback v-if="!$v.citySelected.required"
                  >Debes seleccionar tu ciudad de ubicación.</b-form-invalid-feedback
                >
              </b-form-group>
            </b-col>
        </b-col>
      </b-row>
      <template #modal-footer>
        <div class="w-100">
          <b-row>           
            <b-col lg="6">
              <b-button 
                size="md" 
                variant="warning" 
                @click="submit"
                :disabled="$v.citySelected.$invalid">
                Aceptar
              </b-button>
            </b-col>
            <!-- <b-col lg="6">
              <b-button size="md" variant="danger" @click="hideModal">
                Cancelar
              </b-button>
            </b-col> -->
          </b-row>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {required} from "vuelidate/lib/validators";
export default {
  name: "citySelection",
  props: ["show"],
  data() {
    return {
      cities: [
        { value: null, text: 'Selecciona' },
      ],
      citySelected: null
    };
  },
  validations: {
    citySelected: {
      required,
    }
  },
  created() {
    this.watchs();
  },
  methods: {
    submit(){
      this.$store.commit("defCityId", this.citySelected);
      this.$store.commit("defShowCityModal", false);
    },
    hideModal() {
      this.$store.commit("defShowCityModal", false);
    },
    watchs() {
      this.$store.subscribe((mutation, state) => {
        if (mutation.type === "defCities") {
          let data = this.$store.getters.cities;
          
          data.map(city => {
            let cityItem = {
              value: city.value,
              text: city.label
            }
            this.cities.push(cityItem)
          })          

        }
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
