<template>
  <div class="public-style">
    <CityModal :show="showCityModal" />

    <CButton
      class="wp-button"
      name="Whatsapp"
      size="lg"
      color="xing"
      target="_blank"
      @click.prevent="whatsapp()"
    >
      <CIcon size="lg" :name="'cib-Whatsapp'" />
    </CButton>

    <section
      id="first"
      class="s-bg sections  banner"
      :style="{ backgroundImage: `url(${bannerImage})` }"
    >
      
        <b-col lg="13" class="col-props-title">
          <img
            src="../../assets/images/logoco-v2-min.png"
            class="logo-width2 logo-margin-bottom"
          />
          <h1 class="s-bg-dark-text"> ¡Tu mejor amigo en manos amigas!</h1>
          <div>Paseos, Adiestramientos, Guarderia, Placas de Identidad QR.</div>
            <br /><br /><br />
            <b-row class="video2">
                     
            <b-col md="10" offset-md="1" xs="12">
              <b-embed 
                type="iframe"
                aspect="16by9" 
                src="https://www.youtube.com/embed/Qj549kSgvZ0?rel=0"                 
                allowfullscreen 
              ></b-embed>
            </b-col>
          </b-row>
        </b-col>
           
    </section>

    <!-- Banner of services and products-->
    <br />
 
    <!-- Services original -->
    <section id="services">
      <b-col>
        <h3>Nuestros servicios :</h3>
        <br />
      </b-col>
      <!-- Services lg screens-->
      <b-col lg="12" class="hideLg">
        <b-row>
          <b-col md="3" v-for="service in services" v-bind:key="service.id">
            <b-link href="#" @click.prevent="visitServiceDetail(service.id)">
              <!-- style="height: 26rem;" -->
              <b-card tag="article" class="mb-2 carousel-card" rounded>
                <b-carousel
                  :id="service.id"
                  style="text-shadow: 1px 1px 2px #57a7dd; margin:auto"
                  controls
                  indicators
                  background="#3cafff"
                  :interval="5000"
                  img-width="1024"
                  img-height="480"
                  v-model="slide"
                  @sliding-start="onSlideStart"
                  @sliding-end="onSlideEnd"
                  class="carousel-design"
                >
                  <b-carousel-slide
                    :img-src="url"
                    v-for="url in service.photos"
                    v-bind:key="url"
                  >
                  </b-carousel-slide>
                </b-carousel>
                <div class="card-img-overlay">
                  <div>
                    <b-badge class="md-1" pill variant="danger">{{
                      service.type == 1 ? "Servicio" : "Producto"
                    }}</b-badge>
                  </div>
                </div>
                <br />
                <b-row>
                  <b-col md="12">
                    <div class="link-title text2">
                      <span class="service_name">
                        <strong
                          ><h5>{{ service.name }}</h5></strong
                        >
                      </span>
                    </div>
                    <hr />
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="12">
                    <div class="link-text">
                      <!-- <strong>Precio: </strong>                             -->
                      <h5>
                        <CIcon
                          name="cilMoney"
                          size="sm"
                          height="48"
                          alt="Logo"
                        />
                        <span v-if="service.id == 1 || service.id == 2 || service.id == 35">
                          <small> Desde</small>
                        </span>
                        <span v-if="userCurrency == 'COP'">
                          {{ service.currencyCop | thousand }} COP
                        </span>
                        <span v-if="userCurrency == 'PEN'">
                          {{ service.currencyPen | thousand }} PEN
                        </span>
                      </h5>
                    </div>
                  </b-col>
                  <b-col md="12">
                    <div class="link-text" v-if="service.durationHours != null">
                      <CIcon name="cilClock" size="sm" height="48" alt="Logo" />
                      {{ service.durationHours }}
                      {{ service.durationHours == 1 ? " hora" : " horas" }}
                    </div>
                    <div
                      class="link-text"
                      v-if="service.durationMinutes != null"
                    >
                      <CIcon name="cilClock" size="sm" height="48" alt="Logo" />
                      {{ service.durationMinutes }} minutos
                    </div>
                  </b-col>
                  <b-col md="12" v-if="service.type == 1">
                    <div class="link-text">
                      <CIcon
                        name="cilLocationPin"
                        size="sm"
                        height="48"
                        alt="Logo"
                      />
                      {{cityName}}
                    </div>
                  </b-col>
                  <b-col md="12" v-else>
                    <div class="link-text">
                      Envíos a todo Colombia
                      <CIcon name="cifCo" size="sm" height="48" alt="Logo" />
                    </div>
                  </b-col>
                  <b-col lg="12" v-if="service.giveaway">
                    <b-badge class="md-1" pill variant="success"
                      >Gratis 1er servicio</b-badge
                    >
                  </b-col>
                </b-row>
              </b-card>
            </b-link>
          </b-col>
        </b-row>
      </b-col>
      <!-- Services sm screens -->
       <b-col lg="10" offset-lg="1" class="showSm" >
               
        <b-row>
          <b-col lg="12">            
            <b-card     
              border-variant="info"
              header=" " 
              header-bg-variant="info"
              header-text-variant="white"
              no-body 
              class="overflow-hidden" 
              style="max-width: 540px;"
              v-for="service in services" v-bind:key="service.id">
              <b-link href="#" @click.prevent="visitServiceDetail(service.id)">
                <b-row no-gutters>
                  <b-col style="max-width:30%; text-align: center; padding: 0.2rem; margin:auto">                        
                    <b-carousel 
                      :id="service.id"
                      style="text-shadow: 1px 1px 2px #333; margin:auto"
                      controls
                      indicators
                      background="#ababab"
                      :interval="6000"
                      img-width="1024"
                      img-height="480"
                      v-model="slide"
                      @sliding-start="onSlideStart"
                      @sliding-end="onSlideEnd"
                      >
                      <b-carousel-slide
                          :img-src="url"
                          v-for="url in service.photos"
                          v-bind:key="url">
                      </b-carousel-slide>
                    </b-carousel>  
                    
                  </b-col>
                  <b-col style="max-width:70%">
                    <b-card-body>
                      <b-card-text class="link-text">
                        <strong><h5>{{service.name}}</h5>  </strong>

                        <CIcon name="cilMoney" size="sm" height="48" alt="Logo" />
                        <span v-if="service.id == 1 || service.id == 2 || service.id == 35">
                          <small> Desde</small>
                        </span>
                        <span v-if="userCurrency == 'COP'">                                                              
                            {{ service.currencyCop | thousand }} COP
                        </span>                          
                        <span v-if="userCurrency == 'PEN'">
                            {{ service.currencyPen | thousand }} PEN
                        </span>
                        <br>
                        <div class="link-text" v-if="service.durationHours != null ">
                          <CIcon name="cilClock" size="sm" height="48" alt="Logo" /> {{service.durationHours}}  {{service.durationHours == 1 ? ' hora': ' horas'}}                            
                        </div>
                        <div class="link-text" v-if="service.durationMinutes != null ">
                          <CIcon name="cilClock" size="sm" height="48" alt="Logo" /> {{service.durationMinutes}} minutos
                        </div>
                        <br>
                        <div v-if="service.type==1">
                          <CIcon name="cilLocationPin" size="sm" height="48" alt="Logo" />                            
                          {{cityName}}
                        </div> 
                        <div v-else>
                          Envíos a todo Colombia
                          <CIcon name="cifCo" size="sm" height="48" alt="Logo" />
                        </div>
                       

                      </b-card-text>
                      <br>
                      <b-link href="#" class="link-text2" @click.prevent="visitServiceDetail(service.id)">
                        
                        <strong>
                          Ver detalle y cotizar
                        </strong>
                      </b-link>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-link>
            </b-card>
          </b-col>
        </b-row>
      </b-col>  
    </section>
    <br />

    <!-- What we do-->
    <section id="whatwedo">
      <b-row>
        <b-col md="5" offset-md="1" class="text-center">
          <img
            src="https://storage.googleapis.com/test-goguau.appspot.com/resources/trust-min.jpeg"
            alt="Happiness"
            class="img-expert-props"
          />
        </b-col>
        <b-col md="5" class="vertical-align-center text-center">
          <h4>Seguridad y confianza</h4>
          <p>
            En nuestra plataforma web visualiza el seguimiento GPS y conteo de
            distancia que realiza tu mascota en cada paseo o adiestramiento.
          </p>
        </b-col>
      </b-row>

      <b-row class="expert-section text-center ">
        <b-col md="1"></b-col>
        <b-col md="5" class="text-center">
          <img
            src="https://storage.googleapis.com/test-goguau.appspot.com/resources/technology-min.jpeg"
            alt="Fluid image"
            class="img-expert-props"
          />
        </b-col>
        <b-col md="5" class="vertical-align-center">
          <h4>Tecnología</h4>
          <p>
            Nos apoyamos de la tecnología para brindarte productos y servicios
            que mejoran la calidad de vida de tu mascota.
          </p>
        </b-col>
      </b-row>

      <b-row class="expert-section text-center">
        <b-col md="5" offset-md="1">
          <img
            src="https://storage.googleapis.com/test-goguau.appspot.com/resources/victor2.jpeg"
            alt="Fluid image"
            class="img-expert-props"
          />
        </b-col>
        <b-col md="5" class="vertical-align-center">
          <h4>Responsabilidad social</h4>
          <p>
            Nuestros paseadores son 100% amantes de las mascotas y en su mayoría
            son estudiantes universitarios que trabajan en sus ratos libres para
            financiar sus estudios.
          </p>
        </b-col>
      </b-row>
    </section>

    <!-- how it works -->
    <section class="expert-section">
      <b-row>
        <b-col md="12" class="text-center">
          <h1 class="title">Sobre nosotros</h1>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="10" offset-md="1">
          <b-row class="margin-top-one">
            <b-col md="4">
              <div class="text-center">
                <CIcon name="cilStar" height="48" alt="Logo" />
                <h4 class="margin-top-one">Emprendimiento</h4>
                <p>
                  Somos un emprendimiento Colombiano 100% amantes de las
                  mascotas. Brindamos servicios y productos para tu mejor amigo.
                </p>
              </div>
            </b-col>
            <b-col md="4">
              <div class="text-center">
                <CIcon name="cilScreenDesktop" height="48" alt="Logo" />
                <h4 class="margin-top-one">Tecnología</h4>
                <p>
                  Usamos la tecnología para mejorar la calidad de vida de tu
                  mascota, siempre pensando en su seguridad y tu tranquilidad.
                </p>
              </div>
            </b-col>
            <b-col md="4" class="process-box">
              <div class="text-center">
                <CIcon name="cilAnimal" height="48" alt="Logo" />
                <h4 class="margin-top-one">Un aliado ideal</h4>
                <p>
                  Somos profesionales que estamos dejando huella en la vida de
                  las mascotas. Tu mejor amigo en manos amigas.
                </p>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </section>

    <section>
      <CRow>
        <CCol md="12" style="text-align:center">
          <CCard class="s-bg">
            <CCardBody>
              <CRow>
                <CCol lg="12">
                  <img src="../../assets/images/p1.png" class="logo-width2 logo-margin-bottom"/>
                  <h3>¿Únete como paseador?</h3>
                  <p>Genera ganancias en tus ratos libres.</p>
                  <b-button
                    type="button"
                    class="btn btn-lg p-bg-light-text "
                    @click="visitSignUpWalker"
                    style="margin-right: 0.5rem">
                    <i class="cui-people icons"></i>
                    Regístrate
                  </b-button>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </section>
    <!-- End link Walker -->

    <!-- Footer -->
    <section>
      <CRow>
        <CCol md="12">
          <CCard>
            <CCardBody>
              <CRow>
                <CCol lg="4">
                  <span>
                    <CIcon name="cilLocationPin" height="20" alt="Logo" />
                    {{address}}
                  <br>
                    <CIcon name="cilPhone" height="20" alt="Logo" />
                    {{cityPhone}}
                  </span>
                  
                </CCol>
                <CCol>                  
                  <span> 
                    <strong>Matrícula No.</strong> 221920
                  </span>
                </CCol>
                <CCol lg="4">
                  <span>
                    Síguenos en:
                    <br />
                  </span>
                  <br />
                  <template>
                    <CButton
                      name="facebook"
                      size="sm"
                      color="facebook"
                      href="https://www.facebook.com/goguau.paseos/"
                      target="_blank"
                      style="margin-right:1rem;"
                    >
                      <CIcon size="lg" :name="'cib-Facebook'" /> Facebook
                    </CButton>
                  </template>
                  
                  <template>
                    <CButton
                      name="instagram"
                      size="sm"
                      color="instagram"
                      href="https://www.instagram.com/goguaupaseadores/"
                      target="_blank"
                    >
                      <CIcon size="lg" :name="'cib-Instagram'" /> Instagram
                    </CButton>
                  </template>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </section>
    <!-- End Footer -->
  </div>
</template>

<script>
import services from "../../utils/services.js";
import CityModal from "../modals/citySelection";
export default {
  name: "Main",
  components: { CityModal },
  data() {
    return {
      userCurrency: "COP",
      loading: false,
      services: null,
      bannerImage: require("@/assets/images/bonds3.png"),
      // wp: 'https://api.whatsapp.com/send?phone=573194115792&text=Hola, ',
      wp: "https://api.whatsapp.com/send?phone=, ",
      cityPhone: "3194115792",
      countryIndicative: "57",
      cityName: '',
      cityId: 1,
      address: '',
      slide: 0,
      sliding: null,
      showCityModal: false
    };
  },
  mounted() {
    this.services = services;
    if ("pos" in this.$route.query) {
      let query = this.$route.query;
      let pos = query.pos;
      this.scrollTo(pos);
    }
  },
  created() {
    this.$gtag.pageview("/pages/main");
    this.fetchCities();
    this.watchs();
    if ("cityId" in this.$route.query) {
      this.cityId = +this.$route.query.cityId;
      this.$store.commit("defCityId", this.cityId);
      this.setServices();
    } else {
      //// deactivate this to just show popayan city
      // this.showCityModal = true
      ////this line is to set city = popayan
      this.$router.replace("/pages/main?cityId="+1);
    }
  },
  methods: {
    fetchCities() {
      this.$store
        .dispatch("fetchCities")
        .then(cities => {
          
          let cityFound = cities.find(city => city.value == this.cityId);
          if (cityFound) {
            this.cityPhone = cityFound.phone;
            this.countryIndicative = cityFound.indicative;
            this.cityName = cityFound.label.split(',')[0]
            this.address = cityFound.address
          }
          // this.loading = false;
        })
        .catch(e => {
          // this.loading = false;
        });
    },
    goTo(to) {
      if (to == "facebook") {
        window.location.href = "https://www.facebook.com/goguau.paseos/";
      } else if (to == "terms") {
        let route = this.$router.resolve({ path: "/pages/terms" });
        window.open(route.href, "_blank");
      }
    },
    visitSignUp: function(event) {
      event.preventDefault();
      this.$router.replace("/pages/register");
    },
    visitSignUpWalker: function(event) {
      event.preventDefault();
      this.$router.push("/pages/register?provider=true");
    },
    visitLogin: function() {
      this.$router.replace({ path: "/pages/login" });
    },
    whatsapp() {
      let wpLink =
        this.wp + this.countryIndicative + this.cityPhone + "&text=Hola ";

      console.log(wpLink);

      this.$gtag.event("main", {
        event_category: "whatsapp"
      });
      let message = wpLink + "deseo información de los servicios.";

      window.open(message, "_blank");
    },
    visitServiceDetail: function(serviceId) {
      this.$gtag.event("main", {
        event_category: "visitServiceProfile",
        event_label: serviceId
      });
      this.$router.push("/services/profile/" + serviceId);
    },
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd: function(slide) {
      this.sliding = false;
    },
    scrollTo(pos) {
      let component = "#first";
      if (pos == 2) {
        component = "#first";
      }
      const height = document.querySelector(component).offsetHeight;
      // console.log('height: ',height)
      setTimeout(function() {
        window.scrollTo(0, height);
      }, 100);
    },
    watchs() {
      this.$store.subscribe((mutation, state) => {
        if (mutation.type === "defShowCityModal") {
          this.showCityModal = this.$store.getters.showCityModal;
        }
        if (mutation.type === "defCityId") {
          this.cityId = this.$store.getters.cityId;
          this.$router.replace("/pages/main?cityId="+this.cityId);

          this.setServices();
          this.fetchCities()
        }
      });
    },
    setServices(){
      if(this.cityId == 4 || this.cityId == 37){
        services.splice(2,1)
      }

      services.map(service => {
        if(service.id == 1 || service.id == 2){
          let priceFound = service.prices.find(price => price.cityId == this.cityId)
          if(priceFound){
            service.currencyCop = priceFound.price
          }
        }        
      })
      this.services = services
    }
  }
};
</script>

<style scoped>
.public-style {
  margin: -5px -0px;
}

.intro-card {
  padding: 1rem;
}

.btn-first-margin {
  margin: 1em 0;
}

.service_name {
  display: block;
  text-align: center;
  height: 2.5rem;
  /* color: #1B69B !important; */
}

.banner {
  z-index: 1;
  background-blend-mode: screen;
  background-size: contain;
}

.logo-width2 {
  width: 100%;
}
</style>
